import * as React from 'react';
import { withAlertSnackBar } from '../../HOComponents/AlertSnackBarHOC';
import { Typography, Card, Grid, Box, SvgIcon, Link } from '@material-ui/core';
import jobImage from '../../assets/images/job_pic.png';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import constants, { VENDOR_SUBDOMAIN_MAPPING } from '../../Utils/Constants';
import { useHistory } from 'react-router-dom';
import ResponsiveDialog from '../../components/DialogBox/ResponsiveDialog';
import Chip from '../../components/Chip/Chip';
import { ActivityServices } from '../../Services/activities';
import urlcat from 'urlcat';
import { isMENACountry, getEDMUrl, getPhoBaseUrl } from '../../Utils/CountryUtil';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '../../components/Tooltip/Tooltip';
import { ReactComponent as externalLinkIcon } from '../../assets/images/external-link.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    border: '2px solid #A2ABB5',
    boxShadow: 'none',
  },
  imageContainer: {
    height: '200px',
    width: '200px',
    padding: theme.spacing(2),
    borderRadius: 2,
    backgroundColor: '#e6f2ff',
  },
  jobRoleTitle: {},
}));

const getButtonTitle = (hiringStage) => {
  const title = constants.DOCUMENTS.BUTTON_TITLE_MAP[hiringStage];
  if (title !== undefined) return title;
  return constants.DOCUMENTS.TITLE;
};

function ApplicationCard(props) {
  const classes = useStyles(props);
  const history = useHistory();

  // 'View documents' Link is visible for MENA Countries which have `applicationDCRId` field
  const isEDMLinkVisible = isMENACountry() && !!props?.application?.applicationDCRId && constants.HIRING_STAGES.RTW_STATUSES.includes(props.application.hiringStage);
  const EDMUrl = getEDMUrl();

  const isApplicationDiscontinued = React.useMemo(
    () =>
      constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(props.application.jobStatus) ||
      props.profile.rehireFlag === constants.REHIRE_FLAGS.N,
    [props.application.jobStatus, props.profile.rehireFlag]
  );

  const isApplicationWithdrawn = React.useMemo(
    () => props.application.jobStatus === constants.JOB_STATUS.APPLICATION_WITHDRAWN,
    [props.application.jobStatus]
  );

  // Job Status being Withdrawn or Discontinued overrides hiring stage, making it Withdrawn!
  const { chipColor, chipIcon, chipLabel, tooltipText } = constants.HIRING_STAGES.CHIP_MAP?.[
    isApplicationWithdrawn || isApplicationDiscontinued ? 'Withdrawn' : props.application.hiringStage
  ] ?? {
    ...constants.HIRING_STAGES.CHIP_MAP.fallback,
    chipLabel: props.application.hiringStage,
  };

  const jobDetailsActivity = (jobId, vendorId) => {
    const vendorSubdomain = VENDOR_SUBDOMAIN_MAPPING[vendorId];
    if (jobId && jobId.startsWith('JOB-IN-')) {
      window.open(
        `${sessionStorage.getItem('careerSiteURL')}/app#/jobDetail?jobId=${jobId}&locale=en-IN`.replace(
          'vendorid',
          vendorSubdomain
        ),
        '_blank'
      );
    } else {
      history.push(`${constants.JOB_DETAILS.ROUTE}/${jobId}`);
    }
  };

  const documentsActivity = (application) => {
    history.push({
      pathname: `${constants.DOCUMENTS.ROUTE}/${application.jobId}`,
      state: { application: application },
    });
  };

  const handleWithdrawalActivity = () => {
    let body = {
      candidatesInput: [
        {
          candidateId: props.application.applicationId,
          hiringStage: constants.DOCUMENTS.STATUS.APPLICATION_WITHDRAWN,
          operationType: constants.DOCUMENTS.OPERATION_TYPE.APPLICATION_WITHDRAWN,
          email: localStorage.getItem('emailId'),
        },
      ],
      jobId: props.application.jobId,
      modifiedBy: localStorage.getItem('emailId'),
      actionType: constants.DOCUMENTS.ACTION_TYPE.DELETE_CANDIDATE,
    };
    ActivityServices.candidateActionRequest(body)
      .then((res) => {
        const status = res && res.data && res.data.candidatesOutput[0] && res.data.candidatesOutput[0].status;
        if (status === 'Success') {
          props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.WITHDRAWAL}`);
          window.setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          props.snackbarShowMessage(
            `${constants.ERROR.APPLICATION_WITHDRAWN_ERROR} ${res.data.message}`,
            'error',
            '5000',
            res.data.message
          );
        }
      })
      .catch((res, error) => {
        props.snackbarShowMessage(
          `${constants.ERROR.APPLICATION_WITHDRAWN_ERROR} ${res.data.message}`,
          'error',
          '5000',
          res.data.message
        );
      });
  };

  const handlePHO = () => {
    window.open(
      urlcat(getPhoBaseUrl(), '/:site/:role', {
        site: props.pho.site,
        role: props.pho.role,
        phoId: props.pho && props.pho.phoId,
        candidateId: localStorage.getItem('emailId'),
        applicationId: props.application && props.application.applicationId,
        locale: isMENACountry() ? 'en_US' : '',
        accesstoken: localStorage.getItem('accesstoken'),
        idtoken: localStorage.getItem('idtoken'),
      }),
      '_blank'
    );
  };

  return (
    <Card className={classes.root} data-testid="applicationCard">
      <Grid container spacing={6} direction="column">
        {/* Header */}
        {(isApplicationWithdrawn || isApplicationDiscontinued) && (
          <Grid item>
            {isApplicationWithdrawn && <Alert color="warning">{constants.TEXTS.WITHDRAWN_TEXT}</Alert>}
            {isApplicationDiscontinued && (
              <Alert color="warning">{`${constants.TEXTS.DISCONTINUE_TEXT} ${props.application.vendorName}`}</Alert>
            )}
          </Grid>
        )}
        {/* Body */}
        <Grid container item direction="row" wrap="nowrap" spacing={6}>
          {/* Image */}
          <Grid item hidden={props.isMobile}>
            <Box className={classes.imageContainer}>
              <img height="100%" width="100%" src={jobImage} aria-hidden="true" alt="" />
            </Box>
          </Grid>
          {/* Application Details */}
          <Grid container item spacing={3} direction="column" xs>
            <Grid item>
              <Typography data-testid="jobRoleText" variant="h4">
                {props.application.jobRole}
              </Typography>
            </Grid>
            {!isMENACountry() && (
              <Grid item>
                <Typography data-testid="jobIdText">{`Job Id: ${props.application.jobId}`}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography data-testid="startDateText">{`Start Date: ${props.application.startDate}`}</Typography>
            </Grid>
            <Grid item>
              <Typography data-testid="vendorText">{`Agency: ${props.application.vendorName}`}</Typography>
            </Grid>
            <Grid item>
              <Typography data-testid="cityText">{`Location: ${props.application.city}`}</Typography>
            </Grid>
            <Grid container item spacing={2} alignItems="baseline">
              <Grid item>
                <Typography data-testid={'hiringStageText'} component={'span'}>
                  {'Hiring Stage:'}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm spacing={2} direction="column">
                <Grid item>
                  <Tooltip
                    title={(tooltipText ?? '').replace('{{vendorName}}', props.application.vendorName)}
                    placement="top-start"
                    arrow
                  >
                    <Box sx={{ width: 'fit-content' }}>
                      <Chip
                        data-testid={'hiringStageChip'}
                        label={chipLabel}
                        backgroundColor={chipColor}
                        icon={chipIcon ? <SvgIcon component={chipIcon} viewBox="0 0 16 16" /> : undefined}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
                {isEDMLinkVisible && (
                  <React.Fragment>
                    <Grid item>
                      <Box width="fit-content">
                        <Link href={EDMUrl} target="_blank" underline="always">
                          <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'baseline' }}>
                            View documents
                            <SvgIcon component={externalLinkIcon} viewBox="0 0 16 16" fontSize="inherit" />
                          </Box>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#56616C' }}>
                        Note: Use your Candidate Portal email and password to login and view your documents.
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Action Footer */}
        {!(isApplicationWithdrawn || isApplicationDiscontinued) && (
          <Grid
            container
            item
            direction={props.isMobile ? 'column' : 'row'}
            alignItems={props.isMobile ? 'flex-start' : 'center'}
            justifyContent="space-between"
            spacing={4}
          >
            <Grid xs item>
              {props.pho && <ButtonPrimary Title="Complete Pre-hire orientation" onClick={() => handlePHO()} />}
            </Grid>
            {!isMENACountry() && (
              <React.Fragment>
                <Grid item>
                  <ButtonPrimary
                    isDisabled={
                      !constants.DOCUMENTS.DOCUMENT_EXISTS_STAGES.includes(props.application.hiringStage) ||
                      props.application.jobStatus === constants.JOB_STATUS.DELETED ||
                      props.profile.rehireFlag === constants.REHIRE_FLAGS.N
                    }
                    data-testid="documentsButton"
                    onClick={() => documentsActivity(props.application)}
                    Title={getButtonTitle(props.application.hiringStage)}
                  />
                </Grid>
                <Grid item>
                  <ButtonPrimary
                    data-testid="jobDetailsButton"
                    onClick={() => jobDetailsActivity(props.application.jobId, props.application.vendorId)}
                    Title="Job Details"
                  />
                </Grid>
              </React.Fragment>
            )}
            <Grid item>
              <ResponsiveDialog
                title="Withdraw Application"
                handleApply={handleWithdrawalActivity}
                isDialogBoxDisable={props.application.hiringStage === constants.DOCUMENTS.STATUS.OFFER_LETTER_ACCEPTED}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default withAlertSnackBar(ApplicationCard);
