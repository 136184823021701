import { ReactComponent as DocumentIcon } from '../assets/images/document.svg';
import { ReactComponent as CheckCircleFillIcon } from '../assets/images/check-circle-fill.svg';
import { ReactComponent as DeclinedIcon } from '../assets/images/declined.svg';
import { ReactComponent as WriteIcon } from '../assets/images/write.svg';
import { ReactComponent as PersonIcon } from '../assets/images/person.svg';

let constants = {
    DOCUMENT_STATUS:{
        ACKNOWLEDGED : "ACKNOWLEDGED",
        EXTENDED : "EXTENDED",
        PREVIEW : "PREVIEW",
        REGENERATED_EXTENDED : "REGENERATED_EXTENDED",
        REGENERATED_ACKNOWLEDGED : "REGENERATED_ACKNOWLEDGED",
    },
    JOB_DETAILS_API_ENDPOINT : {
        PRIVATE : "/job/getJobDetailsByJobIdWithAuth/",
        PUBLIC : "/job/getJobDetailsByJobId/"
    },
    SITE: {
        TITLE:'Candidate Portal',
    },
    PATTERN: {
        PASSWORD_PATTERN: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
        EMAIL_ADDRESS_PATTERN : /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
    },
    REHIRE_FLAGS:{
        N: "N",
        y:"Y"
    },
    REACT_QUERY: {
        QUERY_KEY: {
            GET_DOCUMENTS: "getDocuments"
        }
    },
    AUTH: {
        SIGNIN_SUCCESS: 'Sign-in Successful',
        SIGNIN_FAILED: 'Invalid Username/Password . Please try again',
        PASSWORD_RESET_SUCCESS: 'Password successfully reset. Please use your new credentials to login',
        PASSWORD_RESET_FAILED: 'Password reset failed',
        PASSWORD_CHANGE_FAILED: 'Failed to change password',
        PASSWORD_CHANGE_SUCCESS: 'Password successfully changed. Please use your new credentials the next time you login',
        OTP_SENT_SUCCESS: 'OTP successfully sent to your registered email. Please enter it to change your password',
        OTP_SENT_FAILED: 'Failed to send OTP to your registered email',
        OLD_PASSWORD_INVALID: 'Old password invalid',
    },
    SPECTROMETER : {
        APP_NAME: "CandidatePortalSwayamUI",
        SITE_NAME: "CandidatePortalSwayam",
        STAGE:"stage",
    },
    S3:{
        DOCUMENTS_BUCKET: "candidate-portal-documents-bucket-",
    },
    DASHBOARD: {
        ROUTE: '/candidate-dashboard',
        TITLE:'Candidate Dashboard',
        HIRING_STAGE: 'hiringStage',
        JOB_STATUS:'jobStatus'
    },
    HIRING_STAGES:{
        ORDER:{
            "Application Submitted":1,
            "LOI Accepted":2,
            "Offer Letter - Accepted":3,
            "LOI Generated":4,
            "Offer Letter Generated":5
        },
        DB_UI_MAP:{
            "OFFER_LETTER_SENT": "Offer Letter Generated",
            "OFFER_LETTER_ACCEPTED": "Offer Letter - Accepted",
            "OFFER_LETTER_GENERATED": "LOI Accepted",
            "LOI_SENT" : "LOI Generated",
            "LOI_GENERATED" : "PHO Assigned",
            "LOI_ACCEPTED": "LOI Accepted",
            "LOI_GENERATION_FAILED": "Application Under Consideration",
            "OFFER_LETTER_GENERATION_FAILED": "Application Under Consideration",
        },
        // Used to display Chips for each hiring stage
        CHIP_MAP: {
            'Application Submitted': {
                chipColor: 'blue',
                chipIcon: PersonIcon,
                chipLabel: "Application submitted",
                tooltipText: "Your application was submitted.",
            },
            'Assessment Completed': {
                chipColor: 'green',
                chipIcon: CheckCircleFillIcon,
                chipLabel: "Assessment completed",
                tooltipText: "Assessment completed.",
            },
            'PHO Assigned': {
                chipColor: 'yellow',
                chipIcon: WriteIcon,
                chipLabel: "Complete your pre-hire orientation",
                tooltipText: "Complete the pre-hire orientation to continue with your application.",
            },
            'PHO In-Progress': {
                chipColor: 'yellow',
                chipIcon: WriteIcon,
                chipLabel: "Complete your pre-hire orientation",
                tooltipText: "Complete the pre-hire orientation to continue with your application.",
            },
            'PHO Completed': {
                chipColor: 'green',
                chipIcon: CheckCircleFillIcon,
                chipLabel: "Pre-hire orientation complete",
                tooltipText: "Complete the pre-hire orientation to continue with your application.",
            },
            'Onboarding - Complete': {
                chipColor: 'green',
                chipIcon: CheckCircleFillIcon,
                chipLabel: 'Onboarding complete',
                tooltipText: "Your right to work documents have been approved and your Pre-hire orientation training is complete.",
            },
            // RTW Hiring Stages, which are computed from 2 values in DB
            "We're gathering your documents": {
                chipColor: 'yellow',
                chipIcon: DocumentIcon,
                chipLabel: "We're gathering your documents",
                tooltipText: "Your agency, {{vendorName}}, is gathering your right-to-work documents so your work status can be authorized.",
            },
            "We're reviewing your documents": {
                chipColor: 'blue',
                chipIcon: DocumentIcon,
                chipLabel: "We're reviewing your documents",
                tooltipText: "Your documents are being reviewed by internal teams.",
            },
            "Your documents were approved": {
                chipColor: 'green',
                chipIcon: CheckCircleFillIcon,
                chipLabel: "Your documents were approved",
                tooltipText: "Your documents were approved by internal teams.",
            },
            "Withdrawn": {
                chipColor: 'default',
                chipIcon: DeclinedIcon,
                chipLabel: "Withdrawn",
                tooltipText: "You are no longer a candidate for this position.",
            },
            // 'chipLabel' needs to be filled out manually in usage
            fallback: {
                chipColor: 'default',
                chipIcon: null,
                tooltipText: null,
            },
        },
        // Used to display EDM link on these statuses
        RTW_STATUSES: ["We're reviewing your documents", "Your documents were approved", "Onboarding - Complete"]
    },
    DOCUMENTS: {
        ROUTE: `/documents`,
        VIEW: `/view/document`,
        TITLE:'Documents',
        TYPE:{
            LETTER_OF_INTENT: "Letter of Intent",
            OFFER_LETTER: "Offer Letter"
        },
        NAME:{
            OFFER_LETTER: "OfferLetter"
        },
        ALERT_TEXT:" You have been assigned new documents. Click to review them",
        DOCUMENT_EXISTS_STAGES:[
            "LOI Generated",
            "LOI Accepted",
            "Offer Letter Generated",
            "Offer Letter - Accepted",
            "Application Under Consideration"
        ],
        DOCUMENT_PROGRESS_MAP:{
            "Offer Letter":{
                "Offer Letter Generated": 1,
                "Offer Letter - Accepted": 2
            },
            "Letter of Intent":{
                "LOI Generated":1,
                "LOI Accepted":2,
                "Offer Letter Generated": 2,
                "Offer Letter - Accepted": 2
            }
        },
        DMS_DOCUMENT_TYPE_MAP:{
            "OFFER_LETTER":"Offer Letter",
            "LOI":"Letter of Intent"
        },
        DOCUMENT_PREFIX_MAP:{
            "Letter of Intent": "LOI",
            "Offer Letter": "OfferLetter"
        },
        DOCUMENT_FORMAT:{
            "Letter of Intent": "docx",
            "Offer Letter": "pdf"
        },
        BUTTON_TITLE_MAP:{
            "PHO Assigned": "Documents",
            "Application Submitted": "Documents",
            "LOI Generated" : "Review LOI",
            "LOI Accepted": "Documents",
            "Offer Letter Generated" : "Review Offer Letter"
        },
        HIRING_STAGE_DOCUMENT_TYPE_MAP:{
            "LOI Generated" : "Letter of Intent",
            "Offer Letter Generated" : "Offer Letter"
        },
        PROGRESS_STEPS:["Assigned", "Accepted"],
        STATUS:{
            LOI_ACCEPTED:"LOI Accepted",
            OFFER_LETTER_ACCEPTED: "Offer Letter - Accepted",
            LOI_GENERATED:"LOI Generated",
            OFFER_LETTER_SENT : "Offer Letter Generated",
            APPLICATION_WITHDRAWN :"Application - Withdrawn by candidate"
        },
        ALERT_STATUSES:[
            "LOI Generated",
            "Offer Letter Generated"
        ],
        OFFER_LETTER_STAGES: ["Offer Letter Generated", "Offer Letter - Accepted"],
        OPERATION_TYPE:{
            LOI_ACCEPTED:"CANDIDATE_LOI_ACCEPTED",
            OFFER_LETTER_ACCEPTED: "OFFER_LETTER_ACCEPTED",
            APPLICATION_WITHDRAWN: "APPLICATION_WITHDRAWN"
        },
        ACTION_TYPE:{
            UPDATE_CANDIDATE:"UPDATE_CANDIDATE",
            DELETE_CANDIDATE:"DELETE_CANDIDATE"
        }
    },
    CRUD_SUCCESS_ALERTS: {
        CREATED: 'Created successfully',
        UPDATED: 'Updated successfully',
        DELETED: 'Deleted successfully',
        WITHDRAWAL: 'Application withdraw successfully'
    },
    URL_WITHOUT_LOADING_ICON: [
        '/S3/downloadConstruct'
    ],
    JOB_STATUS:{
        ACCEPTED: "ACCEPTED",
        WORK_IN_PROGRESS: "WORK_IN_PROGRESS",
        DELETED:"DELETED",
        ACTIVE: "ACTIVE",
        APPLICATION_WITHDRAWN: "APPLICATION_WITHDRAWN",
        ACTIVE_STATUSES: ["ACTIVE", "ACCEPTED", "WORK_IN_PROGRESS"],
        GREY_STATUSES:["DELETED","DECLINED","APPLICATION_WITHDRAWN"],
        DISCONTINUE_STATUSES:["DELETED","DECLINED","INACTIVE"]
    },
    ERROR: {
        PREPOPULATE_ERROR: 'Something went wrong, unable to fetch data',
        PROFILE_ERROR: 'Something went wrong, unable to fetch profile data',
        UPDATE_ERROR: 'Updation error:',
        CREATE_ERROR: 'Creation error:',
        GENERATE_ERROR: 'Generate error:',
        APPLICATION_WITHDRAWN_ERROR: 'Application withdrawn error:',
        OFFER_LETTER_INVALID: 'Your Application to this job is not valid anymore.',
        INVALID_USERNAME: 'Please enter a valid email address'
    },
    TEXTS:{
        OFFER_LETTER_ACCEPT_TEXT: "I have read the Offer Letter and accept to the terms and conditions provided",
        LOI_ACCEPT_TEXT:"I have read the LOI and accept to the terms and conditions provided",
        DISCONTINUE_TEXT:"Your Application to this job is discontinued by ",
        WITHDRAWN_TEXT:"Your Application to this job is withdrawn by you"
    },
    ALERTS: {
        STAFFING_AGENCY_INFORMATION: "The staffing agency that is listing the job will be your direct employer. During the selection process, the recruitment team of the staffing agency will provide detailed information about your pay and benefits. They will also send to your personal email the detailed job offer, including exact start dates and shift schedules. After receiving this information, associates will then follow the agency instructions to perform their job duties at client sites to be determined by the staffing agency at their sole discretion.",
    },

    // Static job description
    EMPTY_JOB_DETAILS : {
        Overview:{
            Header : "Job Overview : ",
            Description : "",
        },
        DutiesAndResponsibilities:{
            Header : "Duties and Responsibilities : ",
            Description : "",
            Duties : []
        },
        WhatItsLikeAtTheSite:{
            Header : "What it's like at the Site : ",
            Likes : []
        },
        WhyYouWillLoveThisJob:{
            Header : "Why You’ll Love this Job : ",
            Likes : []
        },
        MinimumQualifications:{
            Header : "Minimum qualifications : ",
            Qualifications : []
        }
    },
    JOB_DETAILS : {
        ROUTE:"/jobDetails",
        Overview:{
            Header : "Job Overview : ",
            Description : "You’ll be part of the dedicated operations team at the India's largest ecommerce company. You will unload, inbound, pick, pack and ship orders - in some cases, for super-fast (2-hour or less) deliveries. You may even be part of the team that works with larger items, such as large screen TVs, furniture, and appliances, and be trained on how to use technology to handle these heavy bulk items.",
            SalaryRange : "INR 10,000 to INR 25,000"
        },
        DutiesAndResponsibilities:{
            Header : "Duties and Responsibilities : ",
            Description : "Some of your duties may include:",
            Duties : [
                "Receive and put away inventory",
                "Get customer orders ready and pack them up",
                "Load boxes into trucks for shipment",
                "Use scanners to read bar codes on products",
                "View prompts on screens and follow the direction for some tasks",
                "Troubleshoot problems",
                "Ensure product meets quality requirements",
                "Operate power industrial trucks (PIT) such as a forklift or pallet driver. Equipment training and certification are provided free of cost."
            ]
        },
        WhatItsLikeAtTheSite:{
            Header : "What it's like at the Site : ",
            Likes : [
                        {
                            KEY : "Safety : " ,
                            VALUE : "Your safety is important to us, so we provide protective gear. All teams share safety tips daily."
                        },
                        {
                            KEY : "Surroundings : " ,
                            VALUE : "You’ll be working around moving machines – order pickers, stand-up forklifts, turret trucks, and mobile carts."},
                        {
                            KEY : "Activity : " ,
                            VALUE : "Some activities may require standing in one place for long periods, walking around, or climbing stairs."},
                        {
                            KEY : "Temperature : " ,
                            VALUE : "Even with climate controls, temperatures can vary between 10°C and 35°C in some parts of the warehouse; on hot days, temperatures can be over 35°C in the truck yard or inside trailers."},
                        {
                            KEY : "Noise level : " ,
                            VALUE : "It can get noisy at times. We provide hearing protection if you need it."},
                        {
                            KEY : "Dress code : " ,
                            VALUE : "Relaxed, with a few rules to follow for safety. Comfortable, closed-toe shoes are required. "}
                    ]
        },
        WhyYouWillLoveThisJob:{
            Header : "Why You’ll Love this Job : ",
            Likes : [
                        {
                            KEY : "Stay busy : " ,
                            VALUE : "You and your team are engaged the entire shift."
                        },
                        {
                            KEY : "Schedule options : " ,
                            VALUE : "Depending on where you work, schedules may include full-time (45 hours), reduced-time (30-40 hours) or part-time (20 hours or less), all with the option of working additional hours if needed. Shifts may include overnight, early morning, day, evening, and weekend. "
                        },
                        {
                            KEY : "Career advancement : " ,
                            VALUE : "We have made a pledge to upskill our employees and offer a variety of free training and development programs, and we also have tuition support options for select employee groups. "
                        },
                        {
                            KEY : "New skills : " ,
                            VALUE : "Depending on the role and location, you’ll learn: how to operate a high-capacity printer, a t-shirt folding machine, how to assist with the print production process, or how to receive and process customer returns."
                        },
                        {
                            KEY : "Free training : " ,
                            VALUE : "Depending on the role and location, we’ll teach you how to drive power industrial trucks (PIT) such as forklifts or a pallet driver, how the printing process works, and how to work with the latest technology."
                        },
                        {
                            KEY : "Team environment : " ,
                            VALUE : "Work on small or large teams that support each other. "
                        },
                        {
                            KEY : "Competitive wages : " ,
                            VALUE : "Competitive wage paid, with overtime for more than 45 hours in a week."
                        },
                        {
                            KEY : "Healthcare : " ,
                            VALUE : "Medical Insurance "
                        },
                        {
                            KEY : "Network of Support : " ,
                            VALUE : "health & well-being on and off the job "
                        },
                        {
                            KEY : "Maternity and Parental Leave" ,
                            VALUE : ""
                        },
                        {
                            KEY : "Paid Time Off (PTO)" ,
                            VALUE : ""
                        },
                        {
                            KEY : "Holiday pay opportunities" ,
                            VALUE : ""
                        },
                        {
                            KEY : "Basic life insurance" ,
                            VALUE : ""
                        },
                        {
                            KEY : "On-the-job training and skills development" ,
                            VALUE : ""
                        },
                        {
                            KEY : "Employee Assistance Program" ,
                            VALUE : ""
                        }

                    ]
        },
        MinimumQualifications:{
            Header : "Minimum qualifications : ",
            Qualifications : [
                "10th pass from any recognized board in India",
                "Valid Government ID",
                "Driving License - Mandatory for delivery associates"
            ]
        }
    }
}

export const VENDOR_SUBDOMAIN_MAPPING =  {
    AASA	:	"aasaanjobs",
    WEAV	:	"weavings",
    BUZZ	:   "buzzworks",
    GISN	:	"innovsource",
    JARI	:	"jarikart",
    KLTS	:	"k-l-technical",
    MIMS	:	"million-minds",
    NMSS	:	"needs-manpower",
    NOVH	:	"novho-careers",
    OME	    :	"om-enterprises",
    PNS	    :	"psn",
    QCL1	:	"quess-corp",
    REFA	:	"reliable-first",
    SIB	    :	"sib-services",
    SAAR	:	"saar",
    BHCP	:	"bshr",
    SPNN	:	"spnn",
    TDSP	:	"tds-placements",
    THGP	:	"teamhr",
    WATE	:	"watermelon",
    NVFS	:	"netambit",
}

export const FEATURE_FLAG_LOCAL_STORAGE_KEYS = {
    GRIEVANCE_REPORTING: "IS_GRIEVANCE_FEATURE_ENABLED",
}

export default constants